<template>
  <div>
    <va-modal
      v-model="showEmail"
      size="large"
      :hide-default-actions="true"
      :title="'Email Log'"
    >
      <div class="row">
        <div class="flex xs12 md6 px-2">
          <va-input
            v-model="selectedEmail.to"
            label="Sent to"
            :disabled="true"
          />
        </div>
        <div class="flex xs12 md6 px-2">
          <va-input
            v-model="selectedEmail.date"
            label="Send Date"
            :disabled="true"
          />
        </div>
        <VueJsonPretty :data="selectedEmail" />
      </div>
    </va-modal>
    <va-card title="Mail Log">
      <template slot="actions">
        <div class="row">
          <va-checkbox
            :label="'Show Failed Emails Only'"
            @input="updateTable"
            v-model="showFailedOnly"
          />
        </div>
      </template>
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            v-model="term"
            :placeholder="'Search by name or email'"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            no-clear
          />
        </div>
      </div>
      <va-data-table
        v-if="notSearching"
        :fields="fields"
        :data="mailLogs"
        api-mode
        :per-page="parseInt(perPage)"
        @row-clicked="
          (row) => {
            selectedEmail = row;
            showEmail = true;
          }
        "
        clickable
        hoverable
        :totalPages="totalPages"
        @page-selected="handlePageChange"
        :loading="loading"
      >    
        <template slot="status" slot-scope="props">
          <span
            v-if="
              props.rowData.delivery &&
              props.rowData.delivery.state === 'SUCCESS'
            "
          >
            <i class="fa fa-check" />
          </span>
          <span v-else-if="props.rowData.delivery">
            {{ props.rowData.delivery.state }}
          </span>
          <span v-else> In Transit </span>
        </template>
      </va-data-table>
      <div v-else>
        <va-card>
            <div class="text-center">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            </div>
            <va-progress-bar
              :value="50"
              :buffer="100"
            >
              <p class="py-2">Refreshing Logs, <b style="color: rgb(85, 25, 25);">This may take a few seconds</b></p>
            </va-progress-bar>
        </va-card>
      </div>
    </va-card>
  </div>
</template>
<script>
import firebase from "firebase";
import moment from "moment";
import { debounce } from "lodash";
import VueJsonPretty from "vue-json-pretty";
import axios from '@/scripts/interceptor.js'
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
  },
  data: function () {
    return {
      loading: true,
      log: [],
      showEmail: false,
      showFailedOnly: false,
      selectedEmail: {},
      perPage: "10",
      term: "",
      perPageOptions: ["10", "50", "100", "250"],
      mailLogs: [],
      cachedPages: {},
      notSearching: true,
      pageSnapshots: {},
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      estimatedTotal: false,
    };
  },
  async created() {
    await this.getPaginationCount();
    await this.fetchEmailLogs(this.currentPage, this.perPage);
  },
  watch: {
    term: {
      handler: function () {
        console.log("Term changed");
        this.debouncedSearch();
      },
    },
    currentPage: {
      handler: function () {
        if(!this.notSearching) return;
        this.fetchEmailLogs(this.currentPage, this.perPage);
      },
    },
    perPage: {
      handler: async function () {
        this.resetCache();
        await this.getPaginationCount();
        if (this.term) {
          this.searchEmailLogs(this.term, 0, this.perPage);
        } else {
          await this.fetchEmailLogs(this.currentPage, this.perPage);
        }
      },
    },
  },
  methods: {
    async searchEmailLogs(searchTerm, page, itemsPerPage) {
      this.loading = true;
      try {
        const result = await axios.post(window.firebaseURL + `api/admin/mail/search`,{
          search: searchTerm,
          hitsPerPage: parseInt(itemsPerPage),
          page: page,
        });
        let fetchedExams = []
        for (const doc of result?.data?.data.hits) {
          const data = doc;
          data.id = doc.id;
          data.date = this.parseDate(doc?.delivery?.startTime);
          data.parsedSubject = this.getSubject(data);
          fetchedExams.push(data);
        }
        this.mailLogs = fetchedExams;
        this.totalPages = result?.data?.data?.totalPages;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    debouncedSearch: debounce(async function () {
      if (this.term) {
        this.notSearching = false;
        await this.searchEmailLogs(this.term, 0, this.perPage);
        console.log("Search completed");
        this.notSearching = true;
      } else {
        this.notSearching = false;
        this.resetCache();
        await this.getPaginationCount();
        await this.fetchEmailLogs(1, this.perPage);
        this.notSearching = true;
      }
    }, 500),
    async updateTable() {
      this.notSearching = false;
      this.currentPage = 1;
      await this.resetCache();
      await this.getPaginationCount();
      await this.fetchEmailLogs(this.currentPage, this.perPage);
      this.notSearching = true;
    },
    filterAndSortUsers(exams, showFailed) {
      if (!exams) {
        return [];
      }
      if (showFailed) {
        return exams.filter((exam) => {
          return exam.delivery && exam.delivery.state !== "SUCCESS";
        });
      }
    },
    resetCache() {
      this.cachedPages = {};
      this.pageSnapshots = {};
    },
    checkForLocalEnviroment() {
      if (window.location.hostname === "localhost") {
        return true;
      } else {
        return false;
      }
    },
    async getPaginationCount() {
      this.loading = true;
      try {
        // Find the total number of documents in the collection
        const countRequest = await axios.post(window.firebaseURL + 'api/admin/mail/count', {
          showFailedOnly: this.showFailedOnly,
          perPage: this.perPage,
        });
        
        this.totalItems = countRequest?.data?.count || 0;
        this.totalPages = countRequest?.data?.pages || 1;
        
        if (this.totalItems > 10000) {
          this.estimatedTotal = true;
        } else {
          this.estimatedTotal = false;
        }
      } catch (error) {
        console.error('Erro ao contar documentos:', error);
        // Fallback to estimating the total number of documents
        await this.getPaginationEstimate();
      }
      this.loading = false;
    },
    async getPaginationEstimate() {
      let examsTable = firebase.firestore().collection("email");
      if (this.showFailedOnly) {
        examsTable = examsTable.where("delivery.state", "!=", "SUCCESS");
      }
      
      // Instead of fetching all documents, we fetch a sample size
      // and estimate the total count based on that sample
      const sampleSize = 1000;
      let querySnapshot = await examsTable.limit(sampleSize).get();
      
      // Estimate the total number of documents based on the sample size
      this.totalItems = querySnapshot.size * 10; // Estimating 10 times the sample size
      const itemsPerPage = parseInt(this.perPage);
      this.totalPages = Math.ceil(this.totalItems / itemsPerPage) || 1;
      this.estimatedTotal = true;
    },
    async fetchEmailLogs(page, itemsPerPage, search = "") {
      this.loading = true;
      this.mailLogs = [];
      const pageSize = parseInt(itemsPerPage);
      const targetPage = page;

      // Check if the requested page is already in the cache
      if (this.cachedPages[targetPage] && !search) {
        this.mailLogs = this.cachedPages[targetPage];
        this.loading = false;
        return;
      }

      try {
        // If the page is not in the cache, we need to fetch it from Firestore
        // Now we check if the page is in the cache and if it is, we use it
        if (targetPage > 1 && !this.pageSnapshots[targetPage - 1]) {
          const result = await axios.post(window.firebaseURL + 'api/admin/mail/paginated', {
            page: targetPage,
            pageSize: pageSize,
            showFailedOnly: this.showFailedOnly
          });
          
          if (result?.data?.emails) {
            const fetchedEmails = result.data.emails.map(doc => {
              const data = doc;
              data.date = this.parseDate(data.delivery?.startTime);
              data.parsedSubject = this.getSubject(data);
              return data;
            });
            
            this.mailLogs = fetchedEmails;
            this.cachedPages[targetPage] = fetchedEmails;
            
            if (result.data.lastDoc) {
              this.pageSnapshots[targetPage] = {
                lastDocId: result.data.lastDoc
              };
            }
            
            this.loading = false;
            return;
          }
        }
        
        // Traditional method of fetching data from Firestore
        let examsTable = firebase.firestore().collection("email");
        let examsQuery = null;
        
        if (this.showFailedOnly) {
          examsTable = examsTable.where("delivery.state", "!=", "SUCCESS");
          examsQuery = examsTable
            .orderBy("delivery.state", "desc")
            .limit(pageSize);
        } else {
          examsQuery = examsTable
            .orderBy("delivery.startTime", "desc")
            .limit(pageSize);
        }

        // Use the cached boundaries, if available
        if (targetPage > 1) {
          if (this.pageSnapshots[targetPage - 1]?.end) {
            // Reuse the stored snapshot for the previous page
            examsQuery = examsQuery.startAfter(this.pageSnapshots[targetPage - 1].end);
          } else {
            // Use the last document from the previous page if available
            const result = await axios.post(window.firebaseURL + 'api/admin/mail/paginated', {
              page: targetPage,
              pageSize: pageSize,
              showFailedOnly: this.showFailedOnly
            });
            
            if (result?.data?.emails) {
              const fetchedEmails = result.data.emails.map(doc => {
                const data = doc;
                data.date = this.parseDate(data.delivery?.startTime);
                data.parsedSubject = this.getSubject(data);
                return data;
              });
              
              this.mailLogs = fetchedEmails;
              this.cachedPages[targetPage] = fetchedEmails;
              
              if (result.data.lastDoc) {
                this.pageSnapshots[targetPage] = {
                  lastDocId: result.data.lastDoc
                };
              }
              
              this.loading = false;
              return;
            }
            
            // If the last document is not available, we need to skip to the target page
            const skips = (targetPage - 1) * pageSize;
            let lastVisible = null;
            
            try {
              if (this.showFailedOnly) {
                lastVisible = await examsTable
                  .orderBy("delivery.state", "desc")
                  .limit(Math.min(skips, 1000)) // Limit to 1000 for performance
                  .get()
                  .then((snapshot) => {
                    return snapshot.docs[snapshot.docs.length - 1];
                  });
              } else {
                lastVisible = await examsTable
                  .orderBy("delivery.startTime", "desc")
                  .limit(Math.min(skips, 1000)) // Limit to 1000 for performance
                  .get()
                  .then((snapshot) => {
                    return snapshot.docs[snapshot.docs.length - 1];
                  });
              }
              
              if (lastVisible) {
                examsQuery = examsQuery.startAfter(lastVisible);
              } else {
                // If the last visible document is not available, we need to reset the cache
                this.mailLogs = [];
                this.cachedPages[targetPage] = [];
                this.loading = false;
                return;
              }
            } catch (err) {
              console.error('Error fetching last visible document:', err);
              this.mailLogs = [];
              this.loading = false;
              return;
            }
          }
        }

        let querySnapshot = await examsQuery.get();
        const fetchedExams = [];

        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          data.id = doc.id;
          data.date = this.parseDate(data.delivery?.startTime);
          data.parsedSubject = this.getSubject(data);
          fetchedExams.push(data);
        }
        
        this.cachedPages[targetPage] = fetchedExams;
        this.mailLogs = fetchedExams;
        
        if (querySnapshot.docs.length > 0) {
          this.pageSnapshots[targetPage] = {
            start: querySnapshot.docs[0],
            end: querySnapshot.docs[querySnapshot.docs.length - 1],
          };
        }
      } catch (error) {
        console.error('Error fetching email logs:', error);
      }
      
      this.loading = false;
    },
    handlePageChange(page) {
      if (this.term) {
        this.searchEmailLogs(this.term, page - 1, this.perPage);
      } else {
        this.currentPage = page;
      }
    },
    getSubject(log) {
      try {
        let subject = "No Subject";
        if (log.message && log.message.subject) {
          subject = log.message.subject;
        }
        if (log.template) {
          if (log.template.name) {
            subject = "Template: " + log.template.name;
          }
        }

        return subject;
      } catch (e) {
        console.log("Error parsing log", log);
        console.error(e);
      }
    },
    parseDate(date) {
      try {
        let seconds = date.seconds ?? date;
        return moment(new Date(seconds * 1000)).format(
          "MM/D/YYYY, h:mm:ss a"
        );
      } catch (e) {
        return "Error parsing date... Probably in transit";
      }
    },
    successParse(data) {
      let returnData = '<i class="fa fa-check" />';
      if (data.state !== "SUCCESS") {
        returnData = data.state;
      }
      return returnData;
    },
  },
  computed: {
    filteredData() {
      if ((!this.term || this.term.length < 1) && !this.showFailedOnly) {
        return this.mailLogs;
      }

      return this.mailLogs.filter((item) => {
        let returnLog = false;
        // if (item.delivery) {
        //     const dataString = JSON.stringify(item.delivery);
        //   if (dataString.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
        //     returnLog = true;
        //   }
        // }
        if (item.to.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
          returnLog = true;
        }
        if (
          item.parsedSubject.toLowerCase().indexOf(this.term.toLowerCase()) !==
          -1
        ) {
          returnLog = true;
        }
        if (item.date.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
          returnLog = true;
        }
        if (
          this.showFailedOnly &&
          item.delivery &&
          item.delivery.state === "SUCCESS"
        ) {
          returnLog = false;
        }

        return returnLog;
      });
    },
    fields() {
      return [
        {
          name: "id",
          title: "ID",
        },
        {
          name: "to",
          title: "Receiver",
          sortField: "to",
        },
        {
          name: "parsedSubject",
          title: "Subject / Template",
          sortField: "parsedSubject",
        },

        {
          name: "__slot:status",
          title: "Status",
        },
        {
          name: "date",
          title: "Date",
          sortField: "date",
        },
      ];
    },
  },
};
</script>
